import { useEffect, useMemo, useRef, useState } from "react";

import { Lock, LockOpen } from "@material-ui/icons";
import { Alert, Button, Input, Select, Switch } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components/macro";

import usePlayTo3DDataPrivateSources from "hooks/usePlayTo3DDataPrivateSources";
import usePlayTo3DDataSources from "hooks/usePlayTo3DDataSources";
import usePlayTo3DFields from "hooks/usePlayTo3DFields";
import useUserModules from "hooks/useUserModules";

import { updateXDASettings, useVisState } from "../../context";
import { IpdbBin, IpdbColors, XDASettingsT } from "../../context/types";
import { XdaOptionItem, XdaOptionLabel, XdaOptionsWrapper } from "./XdaOptionsLayout";

interface XDAViewerOptionsProps {
  binUpdateErrorMessage: string;
}

const Xda3DGeoOptions = (props: XDAViewerOptionsProps) => {
  const [{ xda }, visDispatch] = useVisState();
  const {
    bin,
    ipdbColor,
    ipdbField,
    ipdbSource,
    ipdbOpacity,
    reverseColor,
    showIpdb,
    showIpdbLegend
  } = xda.settings;

  const { has3dGeoModel } = useUserModules();

  const { data: fields, refetch: refetchPlayFields } = usePlayTo3DFields(ipdbSource);
  const { data: dataSources, refetch: refetchDataSources } = usePlayTo3DDataSources();
  const { data: privateDataSources, refetch: refetchPrivateDataSources } =
    usePlayTo3DDataPrivateSources();

  const [binSetting, setBinSetting] = useState<IpdbBin>(null);

  const memoizedFields = useMemo(() => {
    return fields?.map((field) => ({
      label: field.displayName,
      value: field.displayName,
      title: field.tooltip ?? field.displayName
    }));
  }, [fields]);

  const momorizedDataSources = useMemo(() => {
    return has3dGeoModel
      ? dataSources?.map((source) => ({
          label: source,
          value: source
        }))
      : privateDataSources?.map((source) => ({
          label: source.source,
          value: source.source
        }));
  }, [has3dGeoModel, dataSources, privateDataSources]);

  // sync option changes with context
  const handleSettingChange = (key: keyof XDASettingsT) => (value) => {
    let updatedValue = value;
    if (key === "bin") {
      updatedValue = {
        lessThan: parseFloat(value.lessThan),
        binSize: parseFloat(value.binSize),
        greaterThan: parseFloat(value.greaterThan),
        isLocked: true
      };
    }

    const nextSettings = { ...xda.settings, [key]: updatedValue };
    updateXDASettings(visDispatch, nextSettings);
  };

  const debouncedUpdateSetting = useRef(
    _debounce((fn) => {
      fn();
    }, 500)
  ).current;

  useEffect(() => {
    refetchPlayFields();
  }, [ipdbSource]);

  useEffect(() => {
    const ipdbFieldInFields = (fields ?? []).find(
      (field) => field.displayName === ipdbField
    );
    if (fields?.length && !ipdbFieldInFields) {
      // Set init value for ipdbField to either porosity or first item.
      const initValue = fields.find((field) => field.name === "porosity") || fields[0];

      const nextSettings = { ...xda.settings, ipdbField: initValue.displayName };
      updateXDASettings(visDispatch, nextSettings);
    } else if (fields?.length == 0) {
      const nextSettings = { ...xda.settings, ipdbField: undefined };
      updateXDASettings(visDispatch, nextSettings);
    }
  }, [fields, ipdbField]);

  useEffect(() => {
    refetchDataSources();
    refetchPrivateDataSources();
  }, []);

  useEffect(() => {
    if (privateDataSources?.length && !has3dGeoModel) {
      // Set the first private data source as the default source
      handleSettingChange("ipdbSource")(privateDataSources[0]);
    }
  }, [has3dGeoModel, privateDataSources]);

  useEffect(() => {
    setBinSetting({
      lessThan: bin?.lessThan,
      binSize: bin?.binSize,
      greaterThan: bin?.greaterThan
    });
  }, [bin]);

  function updateBin(newBin: IpdbBin) {
    debouncedUpdateSetting(() => handleSettingChange("bin")(newBin));
  }

  return (
    <XdaOptionsWrapper>
      <XdaOptionItem>
        <XdaOptionLabel>Show 3D Geo Model</XdaOptionLabel>
        <Switch
          size="small"
          checked={showIpdb}
          onChange={handleSettingChange("showIpdb")}
        />
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Data Source</XdaOptionLabel>
        <Select
          size="small"
          value={ipdbSource}
          options={momorizedDataSources}
          onChange={handleSettingChange("ipdbSource")}
        />
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Field</XdaOptionLabel>
        <Select
          size="small"
          virtual={false}
          value={ipdbField}
          options={memoizedFields}
          onChange={(value) => {
            const field = fields.find((field) => field.displayName === value);
            const hasUnit = field?.unit;
            updateXDASettings(visDispatch, {
              ...xda.settings,
              ipdbField: value,
              ipdbUnit: `${hasUnit ? ` (${field?.unit})` : ""}`,
              bin: {
                ...bin,
                isLocked: false
              }
            });
          }}
        />
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Colour Palette</XdaOptionLabel>
        <Switch
          size="small"
          unCheckedChildren={"Reverse"}
          checkedChildren={"Reverse"}
          checked={reverseColor}
          onChange={handleSettingChange("reverseColor")}
        />
        <Select
          size="small"
          value={ipdbColor}
          options={IpdbColors.map((field) => ({
            label: field,
            value: field
          }))}
          onChange={handleSettingChange("ipdbColor")}
        />
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Unit</XdaOptionLabel>
        {fields?.find((field) => field.displayName === ipdbField)?.unit}
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Bin</XdaOptionLabel>
        <BinLockButton
          type="link"
          icon={!bin?.isLocked || !bin?.binSize ? <LockOpen /> : <LockIconWrapper />}
          onClick={() => {
            updateXDASettings(visDispatch, {
              ...xda.settings,
              bin: {
                ...bin,
                isLocked: !bin?.isLocked
              }
            });
          }}
        />
        <BinContainer>
          <BinItem>
            <BinInputLabel>Less Than</BinInputLabel>
            <Input
              type="number"
              onChange={(evt) => {
                // Cast to unknown first so that the user can input any decimal value.
                // Problem was with the decimal place and values such as 1.0, parsing it directly as a float/number will be 1.
                // So the user could never enter a value like 1.01.
                const inputValue = evt.target.value as unknown as number;
                setBinSetting((prevBinSettings) => ({
                  ...prevBinSettings,
                  lessThan: inputValue
                }));
              }}
              value={binSetting?.lessThan}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateBin(binSetting);
                }
              }}
            />
          </BinItem>
          <BinItem>
            <BinInputLabel>Bin Size</BinInputLabel>
            <Input
              type="number"
              min={0}
              onChange={(evt) => {
                const inputValue = evt.target.value as unknown as number;
                setBinSetting((prevBinSettings) => ({
                  ...prevBinSettings,
                  binSize: inputValue
                }));
              }}
              value={binSetting?.binSize}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateBin(binSetting);
                }
              }}
            />
          </BinItem>
          <BinItem>
            <BinInputLabel>Greater Than</BinInputLabel>
            <Input
              type="number"
              onChange={(evt) => {
                const inputValue = evt.target.value as unknown as number;
                setBinSetting((prevBinSettings) => ({
                  ...prevBinSettings,
                  greaterThan: inputValue
                }));
              }}
              value={binSetting?.greaterThan}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateBin(binSetting);
                }
              }}
            />
          </BinItem>
        </BinContainer>
      </XdaOptionItem>
      <XdaOptionItem>
        <BinUpdateButton
          onClick={() => {
            updateBin(binSetting);
          }}>
          Update Bin Settings
        </BinUpdateButton>
      </XdaOptionItem>
      {props.binUpdateErrorMessage && (
        <Alert message={props.binUpdateErrorMessage} type="error" />
      )}
      <XdaOptionItem>
        <XdaOptionLabel>Legend</XdaOptionLabel>
        <Switch
          size="small"
          checked={showIpdbLegend}
          onChange={handleSettingChange("showIpdbLegend")}
        />
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Legend Opacity</XdaOptionLabel>
        <InputWrapper
          min={0}
          max={1}
          step={0.1}
          size="small"
          type="number"
          value={ipdbOpacity}
          onChange={(e) => handleSettingChange("ipdbOpacity")(e.target.value)}
        />
      </XdaOptionItem>
    </XdaOptionsWrapper>
  );
};

export default Xda3DGeoOptions;

const InputWrapper = styled(Input)`
  max-width: 100px;
  border-radius: var(--border-radius);
`;
const BinContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
`;

const BinInputLabel = styled.span`
  font-size: 1.2rem;
`;

const BinItem = styled.div`
  max-width: 76px;
`;

const BinUpdateButton = styled(Button)`
  border-radius: 20px;
  width: 200px;
  background-color: var(--color-primary);
  height: 30px;
  width: 100%;

  font-weight: 500;
`;

const BinLockButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 100%;
  outline: none;
  color: #a2aaad;
  padding-top: 25px;
  &:hover {
    color: var(--color-primary) !important;
    background-color: inherit !important; /* Prevent background color change */
    border-color: transparent !important; /* Prevent border color change */
  }
`;

const LockIconWrapper = styled(Lock)`
  color: var(--color-primary);
`;
